<template>
  <div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";

export default {
  name: 'Login',
  created() {
    let accessToken = this.$route.query.access_token;
    let refreshToken = this.$route.query.refresh_token;
    if (accessToken && refreshToken) {
      localStorage.setItem('jwt', accessToken);
      localStorage.setItem('refresh_token', refreshToken);

      var decoded = jwt_decode(accessToken);
      localStorage.setItem('username', decoded.name);

      this.$router.push('/profiles');
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
